import { useQuery } from '@tanstack/react-query'
import { IPossibleFiltersRequest } from 'data-transfers/requests'
import { IMatchingPossibleFiltersResponse } from 'data-transfers/responses'

import { IUseQueryHookWithPayload } from '../types'

import { advertMatchesKeys } from './keys'
import { getMatchingCandidateFilter } from './matching.service'

export const useCandidatePossibleFilters = ({
  payload,
  options
}: IUseQueryHookWithPayload<
  IPossibleFiltersRequest,
  IMatchingPossibleFiltersResponse
>) => {
  return useQuery(
    advertMatchesKeys.possibleFilters(payload),
    async () => {
      return await getMatchingCandidateFilter(payload)
    },
    options
  )
}

export default useCandidatePossibleFilters
