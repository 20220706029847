import languages from 'data/languages'

const getFlag = (name?: string) => {
  if (!name) return ''
  for (const i in languages) {
    if (
      languages[i] &&
      languages[i]?.name.toLowerCase() === name.toLowerCase()
    ) {
      return languages[i]?.code ?? ''
    }
  }

  return ''
}

export default getFlag
