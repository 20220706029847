import { z } from 'zod'

const addressValidator = z.object({
  id: z.string().uuid().nullable(),
  country: z.string().trim().min(2),
  city: z.string().trim().min(1),
  streetAddress: z.string().trim().min(1),
  houseNumber: z.string().trim().min(1),
  apartmentNumber: z.string().nullable(),
  zipCode: z.string().trim().min(1)
})

export const advertAddressValidator = z
  .object({
    id: z.string().uuid().nullable(),
    country: z.string().trim().min(2),
    city: z.string().trim().min(1),
    streetAddress: z.string().trim().nullable().optional(),
    houseNumber: z.string().trim().nullable().optional(),
    apartmentNumber: z.string().nullable(),
    zipCode: z.string().trim().nullable().optional()
  })
  .transform((obj) => ({
    ...obj,
    streetAddress: obj.streetAddress || null,
    houseNumber: obj.houseNumber || null,
    zipCode: obj.zipCode || null
  }))

export default addressValidator
