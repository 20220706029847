import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { toast } from 'react-toastify'
import clsx from 'clsx'

import { SectionEditButton } from 'components/buttons'
import Linkify from 'components/Linkify'
import { IEditableSectionsTypes } from 'components/modals/org'
import { IconEdit } from 'icons'

import OrgSocials from './OrgSocials'
import { ISectionType, Social } from './types'

const OrgHeader = ({ org, editable, onEditableClick }: ISectionType) => {
  const { t } = useTranslation(['organisation', 'actions'])
  const openModal = useCallback(
    (
      modal: Extract<
        IEditableSectionsTypes,
        'companyPicture' | 'companyLogo' | 'information'
      >
    ) =>
      () => {
        onEditableClick?.({ modal })
      },
    [onEditableClick]
  )
  return (
    <div className="relative min-h-[450px] lg:w-full">
      {editable && (
        <div
          onClick={openModal('companyPicture')}
          className="absolute top-0 left-0 z-[3] mx-auto  mt-2  ml-2"
        >
          <span className="flex cursor-pointer items-center justify-center rounded-full bg-white py-1 pl-3 pr-4 text-center text-xs font-bold uppercase shadow-sm transition-opacity hover:opacity-80 active:opacity-60">
            <span className="h-4 w-6">
              <IconEdit className=" h-4 w-4" />
            </span>
            <span>{t('edit', { ns: 'actions' })}</span>
          </span>
        </div>
      )}
      <div className="top-0 right-0 z-[3] flex md:absolute">
        <button
          className="bg-white/80 px-5 py-3 font-semibold uppercase transition-colors hover:bg-white/70 active:bg-white/90"
          onClick={async () => {
            await navigator.clipboard.writeText(
              window.location.host + '/org/' + org.slug ?? org.id
            )
            toast(t('toast.linkCopied', { ns: 'actions' }))
          }}
        >
          {t('actions:copyLink')}
        </button>
      </div>

      {org.mainPictureUrl && (
        <Image
          src={org.mainPictureUrl}
          alt={`${org.brandName ?? ''} organisation picture`}
          fill
          className={clsx(
            'object-contain object-left-top',
            editable && 'lg:object-contain lg:object-top'
          )}
        />
      )}

      <div className="absolute bottom-0 left-0 z-[2] flex h-full w-full flex-col items-center justify-end gap-x-10 gap-y-4 bg-white/80 py-4 px-5 md:h-fit md:flex-row md:justify-between md:px-20">
        <div className="flex-0 relative h-40 w-40">
          <Image
            src={org.logoUrl ?? ''}
            alt={`${org.brandName ?? ''} organisation logo`}
            fill
            style={{ objectFit: 'contain', objectPosition: 'top' }}
          />
          {editable && (
            <div
              onClick={openModal('companyLogo')}
              className="absolute bottom-0 mx-auto flex w-full items-center justify-center "
            >
              <span className="flex cursor-pointer items-center justify-center rounded-full bg-white py-1 pl-3 pr-4 text-center text-xs font-bold uppercase shadow-sm transition-opacity hover:opacity-80 active:opacity-60">
                <span className="h-4 w-6">
                  <IconEdit className=" h-4 w-4" />
                </span>
                <span>{t('edit', { ns: 'actions' })}</span>
              </span>
            </div>
          )}
        </div>
        <div className="w-full flex-1">
          <div className="">
            <h1 className="w-full flex-1 flex-wrap pb-1 text-2xl font-bold leading-tight md:flex-1 md:text-4xl">
              {org.brandName}
            </h1>
            {/* TODO: shouldnt be optional */}
            <h5 className="pb-4 font-medium">{org.industry?.value}</h5>
          </div>
          <div className="flex flex-wrap gap-x-5">
            <Linkify linkClass="!text-current no-underline">
              {org.websiteUrl ?? ''}
            </Linkify>
            <div>
              {t('organisation:registrationNumber')}: {org.registrationNumber}
            </div>
            {org.vatPayerNumber ? (
              <div>
                {t('organisation:VAT')}: {org.vatPayerNumber}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex-0">
          <OrgSocials
            socials={org.socials as Social[]}
            editable={editable}
            onEditableClick={onEditableClick}
          />
        </div>
        {editable && (
          <SectionEditButton
            data-cy="overall-information-modal"
            className="absolute top-0 right-0"
            onClick={openModal('information')}
          />
        )}
      </div>
    </div>
  )
}

export default OrgHeader
