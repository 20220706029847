import { z } from 'zod'

import { ytLinkRegex } from 'utils/youtube.utils'

import addressValidator from './addressValidator'
import descriptionValidator from './descriptionValidator'
import languageValidator from './languageValidator'

export const validateYoutubeUrl = (value?: string | null) => {
  if (typeof value === 'string' && !String(value).match(ytLinkRegex)) {
    return 'custom.invalidYoutubeUrl'
  }
}

export const youtubeUrlValidator = z
  .string()
  .nullable()
  .optional()
  .superRefine((str, ctx) => {
    if (validateYoutubeUrl(str)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'custom.invalidYoutubeUrl'
      })
    }
  })

export const orgStep0Validator = z.object({
  registrationNumber: z.string().trim().min(1),
  legalName: z.string().trim().min(3),
  brandName: z.string().trim().min(3),
  vatPayerNumber: z.string().nullable().optional(),
  industryId: z.string(),
  websiteUrl: z.string().optional().nullable(),
  countryCode: z.string()
})

export const orgStep1Validator = z.object({
  addresses: addressValidator.array().refine((data) => data.length > 0, {
    message: 'custom.create1Address'
  })
})

export const orgStep2Validator = z
  .object({
    languages: languageValidator.array().min(1, 'custom.select1Language')
  })
  .superRefine((obj, ctx) => {
    const langs = obj.languages.map((lang) => lang.language)
    if (new Set(langs).size !== langs.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'custom.noDuplicateLangs'
      })
    }
  })

export const orgStep3Validator = z.object({
  descriptions: descriptionValidator.array()
})

export const orgStep4Validator = z.object({
  socials: z.array(
    z.object({
      type: z.enum(['linkedin', 'facebook', 'twitter', 'instagram', 'tiktok']),
      url: z.string().optional()
    })
  )
})

export const orgStep5Validator = z.object({})
export const orgStep6Validator = z.object({})
export const orgStep7Validator = z.object({})
export const orgStep8Validator = z.object({
  subscriptionPlanId: z.string()
})
export const orgStep9Validator = z.object({
  tnc: z.boolean().refine((value) => value, {
    message: 'custom.orgAcceptTerms'
  }),
  emp: z.boolean().refine((value) => value, {
    message: 'custom.orgAcceptEA'
  })
})
