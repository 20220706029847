import {
  IGetJoinAdvertsRequest,
  IMatchingCandidatesInfinityRequest,
  IMatchingCandidatesRequest,
  IMatchingCandidatesSwiperIndexerRequest,
  IMatchingCandidatesSwiperRequest,
  IMatchingRequest,
  IMatchRequest,
  IPossibleFiltersRequest
} from 'data-transfers/requests'

export const candidateMatchesKeys = {
  all: ['advert_matching_data'] as const,
  matching: (request: IMatchingRequest) =>
    [...candidateMatchesKeys.all, request] as const
}

export const advertMatchesKeys = {
  all: ['advert_matches'] as const,
  advert: (advertRequest?: IMatchingCandidatesRequest) =>
    [...advertMatchesKeys.all, advertRequest] as const,
  possibleFilters: (request?: IPossibleFiltersRequest) =>
    [...advertMatchesKeys.all, request] as const,
  joinMatches: (payload: IGetJoinAdvertsRequest) => [
    ...advertMatchesKeys.all,
    'join-adverts',
    payload
  ],
  match: (payload: IMatchRequest) => [
    ...advertMatchesKeys.all,
    'match',
    payload
  ]
}

export const advertMatchSwiperKeys = {
  all: ['advert_match_swiper'] as const,
  indexer: (payload: IMatchingCandidatesSwiperIndexerRequest) =>
    [...advertMatchSwiperKeys.all, payload] as const,
  advert: (payload: IMatchingCandidatesSwiperRequest) =>
    [...advertMatchSwiperKeys.all, payload] as const
}

export const advertMatchesInfinityKeys = {
  all: ['advert_matches_infinity'] as const,
  advert_infinity: (advertRequest: IMatchingCandidatesInfinityRequest) =>
    [...advertMatchesInfinityKeys.all, advertRequest] as const
}
