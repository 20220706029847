import { TFunction } from 'next-i18next'

import { pad } from './input.util'

export const formatYearExperience = (years: number, count = 5) => {
  if (years === 0) {
    return '<1'
  }
  return years > count ? `${count}+` : years
}

export const formatDateForInput = (
  date?: string | Date | null,
  props?: { returnIso?: boolean }
) => {
  const { returnIso } = props ?? {}

  if (!date) {
    return null
  }

  const newDate = new Date(date)

  if (newDate < new Date('1900-01-01')) {
    return null
  }

  const iso = newDate.toISOString().substring(10)

  const year = newDate.getFullYear()
  const month = newDate.getMonth() + 1
  const day = newDate.getDate()
  // str date
  let monthStr = (newDate.getMonth() + 1).toString()
  let dayStr = newDate.getDate().toString()

  if (day < 10) {
    dayStr = '0' + day
  }
  if (month < 10) {
    monthStr = '0' + month
  }

  const dateStr = `${year}-${monthStr}-${dayStr}`

  return returnIso ? `${dateStr}${iso}` : dateStr
}

export const formatEmploymentHistoryDate = (
  start: string,
  end: string | null,
  t: TFunction
) => {
  //'\u00A0' - Non-Breaking Space in unicode
  const startDate = new Date(start)
  const from = `${startDate.getFullYear()}\u00A0${pad(
    startDate.getMonth() + 1,
    2
  )}`
  let to = t('date.now', { ns: 'cv_form' })
  if (end) {
    const endDate = new Date(end)
    to = `${endDate.getFullYear()}\u00A0${pad(endDate.getMonth() + 1, 2)}`
  }

  return `${from} -\u00A0${to}`
}
