import { useState } from 'react'
import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper/types/swiper-class'

import Modal from 'components/modals/Modal'

import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { IGalleryModalProps } from '../types'

import GalleryModalItem from './GalleryModalItem'

import 'swiper/css'
const GalleryModal = ({
  startFrom,
  content,
  galleryName,
  onClose
}: IGalleryModalProps) => {
  const [swiper, setSwiperInstance] = useState<SwiperClass>()
  const [activeIndex, setActiveIndex] = useState(0)
  swiper?.on('slideChange', (swiper) => {
    setActiveIndex(swiper.activeIndex)
  })
  return (
    <Modal isOpen={startFrom !== null} onClose={onClose}>
      <Swiper
        slidesPerView={1}
        onSwiper={setSwiperInstance}
        initialSlide={startFrom || 0}
        pagination
        navigation
        modules={[Navigation, Pagination]}
        className="navigation h-[100vh] max-h-[400px] w-[100vw] md:h-[60vh] md:max-h-[800px] md:max-w-[1200px] lg:h-[50vh] lg:w-[90vw] xl:h-[60vh] xl:w-[80vw]"
      >
        {content.map((item, index) => (
          <SwiperSlide key={item} className="">
            <GalleryModalItem
              src={item}
              alt={`${galleryName ?? 'HRizer'} gallery item ${index}`}
              isActive={activeIndex === index}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Modal>
  )
}

export default GalleryModal
