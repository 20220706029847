import { useQuery } from '@tanstack/react-query'
import { IAdvert } from 'data-transfers/dto'
import { IGetJoinAdvertsRequest } from 'data-transfers/requests'

import { IUseQueryHookWithPayload } from 'hooks/types'

import { advertMatchesKeys } from './keys'
import { getJoinMatchAdverts } from './matching.service'

const useJoinAdverts = ({
  payload,
  options
}: IUseQueryHookWithPayload<IGetJoinAdvertsRequest, IAdvert[]>) => {
  return useQuery(
    advertMatchesKeys.joinMatches(payload),
    async () => {
      const response = await getJoinMatchAdverts(payload)

      return response
    },
    {
      retry: false,
      keepPreviousData: true,
      ...options
    }
  )
}

export default useJoinAdverts
