import { useQuery } from '@tanstack/react-query'
import { IMatch } from 'data-transfers/dto'
import { IMatchRequest } from 'data-transfers/requests'

import { handleQueryError } from 'utils/queryErrorHandler'

import { IUseQueryHookWithPayload } from '../types'

import { advertMatchesKeys } from './keys'
import { getMatch } from './matching.service'

export const useShortMatchInfo = ({
  payload,
  options
}: IUseQueryHookWithPayload<IMatchRequest, IMatch | null>) => {
  return useQuery(
    advertMatchesKeys.match(payload),
    async () => {
      return await getMatch(payload)
    },
    {
      retry: false,
      keepPreviousData: true,
      onError: handleQueryError,
      ...options
    }
  )
}

export default useShortMatchInfo
