import { ReactNode } from 'react'
import clsx from 'clsx'
import LinkifyIt from 'linkify-it'
import tlds from 'tlds'

import { ILinkComponentProps, ILinkifyProps } from './types'

const linkifyIt = new LinkifyIt()
linkifyIt.tlds(tlds)

const LinkComponent = ({ url, text, key, className }: ILinkComponentProps) => {
  return (
    <a
      className={clsx('text-blue-600 underline hover:no-underline', className)}
      href={url}
      key={key}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  )
}

const parseText = ({ children: fullText, linkClass }: ILinkifyProps) => {
  if (fullText === '') {
    return fullText
  }

  const matches = linkifyIt.match(fullText)
  if (!matches) {
    return fullText
  }

  const elements: ReactNode[] = []
  let lastIndex = 0

  matches.forEach((match, i) => {
    // Push preceding text if there is any
    if (match.index > lastIndex) {
      elements.push(fullText.substring(lastIndex, match.index))
    }
    const { url, text } = match
    const component = LinkComponent({ url, text, key: i, className: linkClass })

    elements.push(component)

    lastIndex = match.lastIndex
  })

  // Push remaining text if there is any
  if (fullText.length > lastIndex) {
    elements.push(fullText.substring(lastIndex))
  }

  return elements.length === 1 ? elements[0] : elements
}

const Linkify = ({ children, linkClass }: ILinkifyProps) => {
  return <>{parseText({ children, linkClass })}</>
}
export default Linkify
