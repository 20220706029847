import { useQuery } from '@tanstack/react-query'
import { IMatchCandidateBase } from 'data-transfers/dto'
import { IMatchingCandidatesRequest } from 'data-transfers/requests'
import { IPaginated } from 'data-transfers/responses'

import { handleQueryError } from 'utils/queryErrorHandler'

import { IUseQueryHookWithPayload } from '../types'

import { advertMatchesKeys } from './keys'
import { getCandidateMatchesByAdvertId } from './matching.service'

export const useAdvertMatches = ({
  payload,
  options
}: IUseQueryHookWithPayload<
  IMatchingCandidatesRequest,
  IPaginated<IMatchCandidateBase>
>) => {
  return useQuery(
    advertMatchesKeys.advert(payload),
    async () => {
      return await getCandidateMatchesByAdvertId(payload)
    },
    {
      retry: false,
      keepPreviousData: true,
      refetchInterval: 1000 * 15, // 15sec
      onError: handleQueryError,
      ...options
    }
  )
}

export default useAdvertMatches
