import { useInfiniteQuery } from '@tanstack/react-query'
import { IMatchingCandidatesInfinityRequest } from 'data-transfers/requests'

import { IUseQueryHookWithPayload } from '../types'

import { advertMatchesInfinityKeys } from './keys'
import { getCandidateMatchesByAdvertId } from './matching.service'
import { IUseMatchingCandidatesInfinity } from './types'

const useAdvertMatchesInfinity = ({
  payload,
  options
}: IUseQueryHookWithPayload<
  IMatchingCandidatesInfinityRequest,
  IUseMatchingCandidatesInfinity
>) => {
  const limit = payload.limit ?? 0
  return useInfiniteQuery(
    advertMatchesInfinityKeys.advert_infinity(payload),
    async ({ pageParam = 0 }) => {
      const offset = limit * pageParam
      return await getCandidateMatchesByAdvertId({
        ...payload,
        offset
      })
    },
    {
      keepPreviousData: false,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        const total = lastPage?.pagination?.total ?? 0
        const totalPages = Math.ceil(total / limit)

        if (allPages.length < totalPages) {
          return allPages.length
        }
        return undefined
      },
      ...options
    }
  )
}

export default useAdvertMatchesInfinity
