import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { ILanguage } from 'data-transfers/dto'

import { languagesDict } from 'data/languages'

import { IUseOrgLanguagesProps } from './types'

const useOrgLanguages = <T extends ILanguage>({
  languages,
  lang
}: IUseOrgLanguagesProps<T>) => {
  const { locale } = useRouter()
  const values = languagesDict[lang ?? locale]

  const translatedLanguages = useMemo(
    () =>
      languages?.map(({ language, ...rest }) => ({
        ...rest,
        ...values.find((lang) => lang.name === language)
      })),
    [languages, values]
  )

  return { translatedLanguages }
}

export default useOrgLanguages
