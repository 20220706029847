import { ILocale } from 'types/app'

import countries from '../data/countries'
import { ICountry } from '../data-transfers/dto/country'

export interface IFormatAddress {
  country: OptionalValue
  city: OptionalValue
  streetAddress?: OptionalValue
  houseNumber?: OptionalValue
  zipCode?: OptionalValue
  lang?: ILocale
  reverse?: boolean
}

export type OptionalValue = string | undefined | null

interface IUseCountryNameProps {
  country?: string | null
  lang: ILocale
}

export const getCountryName = ({ country, lang }: IUseCountryNameProps) => {
  const countryValue: ICountry | undefined = countries.find(
    (option) => option.name === country
  )

  return countryValue ? countryValue.translate[lang] : country
}

export const formatAddress = ({
  country,
  city,
  streetAddress,
  houseNumber,
  zipCode,
  lang = 'en',
  reverse
}: IFormatAddress) => {
  const countryName = getCountryName({ country, lang })

  const addressParts = [countryName, city, streetAddress, houseNumber, zipCode]
  if (reverse) {
    addressParts.reverse()
  }
  const address = addressParts.filter((x) => !!x?.trim()).join(', ')

  return address ? address : '-'
}
