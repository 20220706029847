import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { IAddress } from 'data-transfers/dto'

import { SectionEditButton } from 'components/buttons'
import {
  IconCircleGenders,
  IconCircleLocation,
  IconCircleRate,
  IconCircleSalary,
  IconCircleTeam
} from 'icons'

import { formatAddress } from 'utils/address.util'

import { OrgLanguages } from './index'
import OrgBullet from './OrgBullet'
import { ISectionType } from './types'

const OrgBulletsSection = ({
  org,
  editable,
  onEditableClick
}: ISectionType) => {
  const { t } = useTranslation(['organisation', 'org_form'])
  const { locale } = useRouter()
  const openAddressModal = useCallback(
    (arrayIndex: number) => () => {
      onEditableClick?.({ modal: 'address', arrayIndex })
    },
    [onEditableClick]
  )

  const renderLocation = useCallback(
    (addresses: IAddress[] | null) =>
      !!addresses && (
        <OrgBullet
          underLabel
          className="ml-5 w-auto min-w-[calc(50%-1.25rem)] "
          icon={IconCircleLocation}
          label={t('location.header', { ns: 'organisation' })}
        >
          {addresses.map(({ streetAddress, city, country }, index) => (
            <div className="flex items-center " key={index}>
              {formatAddress({
                country,
                city,
                streetAddress,
                lang: locale,
                reverse: true
              })}
              {editable && (
                <SectionEditButton
                  data-cy={`edit-location-${index}`}
                  isPlain
                  className="ml-2 "
                  onClick={openAddressModal(index)}
                />
              )}
            </div>
          ))}
        </OrgBullet>
      ),
    [editable, locale, openAddressModal, t]
  )

  return (
    <div className=" relative bg-white pt-10 pb-5 md:pt-20 md:pb-10">
      {editable && (
        <SectionEditButton
          data-cy="add-location-modal"
          variant="add"
          className="absolute top-4 right-0"
          onClick={openAddressModal(org.addresses.length)}
        />
      )}

      <section className="-ml-5 flex flex-wrap gap-y-5 ">
        {editable && !org.addresses.length && (
          <div className="fade-in text-medium ml-5 flex items-center justify-center text-error-400">
            {t('address.error', { ns: 'org_form' })}
          </div>
        )}
        {editable
          ? renderLocation(org.addresses)
          : renderLocation(org?.addresses[0] ? [org?.addresses?.[0]] : null)}

        {!!org.employeeCount && (
          <OrgBullet
            underLabel
            className="ml-5 w-auto min-w-[calc(50%-1.25rem)] "
            icon={IconCircleTeam}
            label={t('companySize.header', { ns: 'organisation' })}
          >
            {t('companySize.description', {
              value: org.employeeCount,
              ns: 'organisation'
            })}
          </OrgBullet>
        )}
        {(!!org.averageSalaryMen || !!org.averageSalaryWomen) && (
          <OrgBullet
            underLabel
            className="ml-5 w-auto min-w-[calc(50%-1.25rem)] "
            icon={IconCircleGenders}
            label={t('salaryGender.header', { ns: 'organisation' })}
          >
            {t('salaryGender.description', {
              men: org.averageSalaryMen,
              women: org.averageSalaryWomen,
              ns: 'organisation'
            })}
          </OrgBullet>
        )}
        {!!org.revenue && (
          <OrgBullet
            underLabel
            className="ml-5 w-auto min-w-[calc(50%-1.25rem)] "
            icon={IconCircleRate}
            label={t('revenue.header', { ns: 'organisation' })}
          >
            {t('revenue.description', {
              value: org.revenue,
              ns: 'organisation'
            })}
          </OrgBullet>
        )}
        {!!org.averageSalary && (
          <OrgBullet
            underLabel
            className="ml-5 w-auto min-w-[calc(50%-1.25rem)] "
            icon={IconCircleSalary}
            label={t('salary.header', { ns: 'organisation' })}
          >
            {t('salary.description', {
              average: org.averageSalary,
              ns: 'organisation'
            })}
          </OrgBullet>
        )}

        {!!org.languages.length && !editable && (
          <OrgBullet
            underLabel
            className="ml-5 w-auto min-w-[calc(50%-1.25rem)] "
            icon={IconCircleSalary}
            label={t('officialLanguages.header', { ns: 'organisation' })}
          >
            <OrgLanguages languages={org.languages} className="mt-4" />
          </OrgBullet>
        )}
      </section>
    </div>
  )
}

export default OrgBulletsSection
