import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IMatch } from 'data-transfers/dto'
import { CandidateStatus } from 'enums'

import { IUseMutation } from 'hooks/types'

import { advertMatchesKeys, candidateMatchesKeys } from './keys'
import { updateMatchingStatus } from './matching.service'
import { IUseUpdateMatchingStatus } from './types'

const useUpdateCandidateMatch = ({
  onSuccess,
  ...options
}: IUseMutation<CandidateStatus, unknown, IUseUpdateMatchingStatus>) => {
  const queryClient = useQueryClient()

  return useMutation(
    (data) => updateMatchingStatus({ ...data, who: 'candidate' }),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(candidateMatchesKeys.all)

        queryClient.setQueryData<IMatch>(
          advertMatchesKeys.match({
            candidateId: variables.candidateId,
            advertId: variables.advertId
          }),
          (qData) =>
            qData
              ? {
                  ...qData,
                  candidateStatus: variables.advertStatus as CandidateStatus
                }
              : qData
        )
        onSuccess?.(data, variables, context)
      },
      ...options
    }
  )
}

export default useUpdateCandidateMatch
