import Link from 'next/link'
import { useMemo } from 'react'

import { ISocialButtonProps } from './types'

const SocialButton = ({ url, icon: Icon, baseUrl }: ISocialButtonProps) => {
  let newUrl = url
  if (newUrl[0] === '/') {
    newUrl = newUrl.slice(1)
  }
  const socialUrl = useMemo(() => {
    if (url.includes('https://') || url.includes('http://')) {
      return newUrl
    }
    if (url.slice(0, 4) === 'www.') {
      return `https://${newUrl}`
    }
    if (url[0] === '/') {
      return `https://${baseUrl}${newUrl}`
    }
    if (url.includes(baseUrl)) {
      return `https://${newUrl}`
    }
    return `https://${baseUrl}${newUrl}`
  }, [url, baseUrl, newUrl])

  return (
    <Link href={socialUrl} target="_blank" rel="noopener noreferrer">
      <Icon className="h-8 w-8" />
    </Link>
  )
}

export default SocialButton
