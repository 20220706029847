import { z } from 'zod'

import { allLocales } from 'constants/locales'

const descriptionValidator = z.object({
  value: z.string().trim().min(1).max(1000),
  languageCode: z.enum(allLocales),
  isDefaultValue: z.boolean().optional()
})

export const adDescriptionValidator = z.object({
  value: z.string().trim().min(0).optional(),
  languageCode: z.enum(allLocales).optional(),
  isDefaultValue: z.boolean().optional()
})

export type Description = z.infer<typeof descriptionValidator>

export default descriptionValidator
