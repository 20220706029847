import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AdvertStatus } from 'enums'

import { IUseMutation } from 'hooks/types'

import { advertMatchesKeys } from './keys'
import { updateMatchingStatus } from './matching.service'
import { IUseUpdateMatchingStatus } from './types'

const useUpdateAdvertMatchStatus = ({
  onSuccess,
  ...options
}: IUseMutation<AdvertStatus, unknown, IUseUpdateMatchingStatus>) => {
  const queryClient = useQueryClient()

  return useMutation(
    (data) => updateMatchingStatus({ ...data, who: 'advert' }),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(advertMatchesKeys.all)
        onSuccess?.(data, variables, context)
      },
      ...options
    }
  )
}

export default useUpdateAdvertMatchStatus
