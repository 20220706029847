import { PropsWithChildren } from 'react'
import clsx from 'clsx'

import { IOrgBulletProps } from './types'

export const OrgBullet = ({
  icon: Icon,
  label,
  children,
  className,
  underLabel
}: PropsWithChildren<IOrgBulletProps>) => {
  return (
    <div
      className={clsx(
        'flex gap-x-2 md:text-base',
        className,
        underLabel ? 'items-start' : 'items-center'
      )}
    >
      <Icon className="h-8 w-8 flex-none" />
      <div className={clsx('flex', underLabel && 'flex-col')}>
        <span className="font-semibold md:min-w-[8rem]">{label}</span>
        <span className="relative">{children}</span>
      </div>
    </div>
  )
}

export default OrgBullet
