import { LanguageLevel as LanguageLevelEnum } from 'enums'
import { z } from 'zod'

const languageValidator = z.object({
  id: z.string().uuid().optional(),
  language: z.string()
})

const languageLevelValidator = z.object({
  id: z.string().uuid().optional(),
  language: z.string({ invalid_type_error: 'custom.required' }).optional(),
  languageLevel: z
    .nativeEnum(LanguageLevelEnum, {
      invalid_type_error: 'custom.required'
    })
    .optional()
})

export { languageLevelValidator, languageValidator }
export default languageValidator
