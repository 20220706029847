import Image from 'next/image'

import { IconYoutube } from 'icons'

import { youtubeUrlValidator } from 'validators/orgStepValidator'
import { getYoutubeVideoImage } from 'utils/youtube.utils'

import { IMediaItem } from '../types'

const getImageSrc = (src: string) => {
  const isYoutube = youtubeUrlValidator.safeParse(src).success
  if (isYoutube) return { src: getYoutubeVideoImage(src), isVideo: true }
  return { src, isVideo: false }
}

const GalleryItem = ({ src, alt, onClick, ...props }: IMediaItem) => {
  const { src: imageSrc, isVideo } = getImageSrc(src)
  return (
    <div
      onClick={onClick}
      className={` group box-border flex h-full items-center justify-center border-[1px] border-transparent after:pointer-events-none after:absolute  after:left-0 after:top-0  after:h-full after:w-full ${
        isVideo
          ? 'hover:after:bg-white hover:after:opacity-40'
          : 'lg:hover:after:border-[1px] lg:hover:after:border-red-550'
      }`}
    >
      <Image src={imageSrc} alt={alt} {...props} />
      {isVideo && (
        <IconYoutube className="pointer-events-none absolute left-[50%] top-[50%] z-10 w-14 translate-x-[-50%] translate-y-[-50%]  text-white group-hover:text-red-900" />
      )}
    </div>
  )
}
export default GalleryItem
