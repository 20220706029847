import clsx from 'clsx'

import getFlag from 'utils/getFlag'

import { IOrgLanguagesProps } from './types'
import useOrgLanguages from './useOrgLanguages'

const OrgLanguages = ({ languages, className }: IOrgLanguagesProps) => {
  const { translatedLanguages } = useOrgLanguages({ languages })
  return (
    <div className={clsx('flex flex-wrap gap-x-4 gap-y-2', className)}>
      {translatedLanguages.map((lang) => (
        <span
          key={lang?.name}
          className="rounded-full border border-slate-500 p-2"
        >
          <span
            className={clsx('fp fp-md fp-rounded', getFlag(lang?.name))}
          ></span>
          <span className="ml-2 mr-1">{lang?.label}</span>
        </span>
      ))}
    </div>
  )
}

export default OrgLanguages
