import { useQuery } from '@tanstack/react-query'
import { IMatchingCandidatesSwiperIndexerRequest } from 'data-transfers/requests'
import { ISwiperIndexer } from 'data-transfers/responses'

import { handleQueryError } from 'utils/queryErrorHandler'

import { IUseQueryHookWithPayload } from '../types'

import { advertMatchSwiperKeys } from './keys'
import { getAdvertSwiperIndexer } from './matching.service'

export const useAdvertSwiperIndexer = ({
  payload,
  options
}: IUseQueryHookWithPayload<
  IMatchingCandidatesSwiperIndexerRequest,
  ISwiperIndexer
>) => {
  return useQuery(
    advertMatchSwiperKeys.indexer(payload),
    async ({ signal }) => {
      return await getAdvertSwiperIndexer({ ...payload, signal })
    },
    {
      onError: handleQueryError,

      ...options
    }
  )
}

export default useAdvertSwiperIndexer
