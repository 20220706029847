import { FC, useCallback, useMemo } from 'react'

import { SectionEditButton } from 'components/buttons'
import {
  IconFacebook,
  IconInstagram,
  IconLinkedin,
  IconTiktok,
  IconTwitter
} from 'icons'

import SocialButton from './SocialButton'
import { IOrgSocialsProps } from './types'

const OrgSocials = ({
  socials,
  editable,
  onEditableClick
}: IOrgSocialsProps) => {
  const openSocialModal = useCallback(() => {
    onEditableClick?.({ modal: 'socialNetwork' })
  }, [onEditableClick])
  const actualSocials = useMemo(
    () => socials.filter((social) => social.url?.length),
    [socials]
  )
  return (
    <div className="flex max-h-32 flex-[1_1_80px] flex-row flex-wrap items-end justify-end gap-x-2 gap-y-2 md:flex-col md:justify-start">
      {socials.map((social, index) => {
        if (!social.url) return null
        let baseUrl = ''
        let icon: FC<{ className?: string }> = () => null
        let url = social.url
        switch (social.type) {
          case 'facebook':
            icon = IconFacebook
            baseUrl = 'facebook.com/'
            break
          case 'linkedin':
            icon = IconLinkedin
            baseUrl = 'linkedin.com/company/'
            break
          case 'twitter':
            icon = IconTwitter
            baseUrl = 'twitter.com/'
            break
          case 'instagram':
            icon = IconInstagram
            baseUrl = 'instagram.com/'
            break
          case 'tiktok':
            // will go to 404 if user url nickname doesn't start with @
            icon = IconTiktok
            url = social.url.includes('@') ? social.url : `@${social.url}`
            baseUrl = 'tiktok.com/'
            break
        }

        return (
          <SocialButton
            key={index}
            icon={icon}
            url={social.url}
            baseUrl={baseUrl}
          />
        )
      })}

      {editable && (
        <SectionEditButton
          className="flex h-8 w-8 items-center justify-center"
          isPlain
          variant={actualSocials.length ? 'edit' : 'add'}
          onClick={openSocialModal}
        />
      )}
    </div>
  )
}

export default OrgSocials
