import { useQuery } from '@tanstack/react-query'
import { IPossibleFiltersRequest } from 'data-transfers/requests'
import { IMatchingAdvertFilterResponse } from 'data-transfers/responses'

import { IUseQueryHookWithPayload } from '../types'

import { advertMatchesKeys } from './keys'
import { getMatchingAdvertFilter } from './matching.service'

const useAdvertPossibleFilters = ({
  payload,
  options
}: IUseQueryHookWithPayload<
  IPossibleFiltersRequest,
  IMatchingAdvertFilterResponse
>) => {
  return useQuery(
    advertMatchesKeys.possibleFilters(payload),
    async () => {
      return await getMatchingAdvertFilter(payload)
    },
    options
  )
}

export default useAdvertPossibleFilters
