import { useCallback, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import GalleryItem from './components/GalleryItem'
import GalleryModal from './components/GalleryModal'
import { IGalleryProps } from './types'

import 'swiper/css'

const Gallery = ({
  content,
  popup = true,
  galleryName,
  itemWidth = 320
}: IGalleryProps) => {
  const [imageIndex, setImageImage] = useState<null | number>(null)
  const openImage = useCallback(
    (index: number) => () => {
      setImageImage(index)
    },
    []
  )

  if (!content.length) {
    return null
  }

  return (
    <>
      <Swiper
        spaceBetween={20}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          1280: {
            slidesPerView: 'auto',
            spaceBetween: 20
          },
          1536: {
            slidesPerView: 'auto',
            spaceBetween: 20
          }
        }}
        className="fade-in-delay overflow-x-hidden"
        style={{
          height: '100%'
        }}
      >
        {content.map((src, index) => (
          <SwiperSlide key={src} className="fade-in-delay xl:!w-auto">
            <GalleryItem
              style={{ height: '100%', width: '300px', objectFit: 'cover' }}
              src={src}
              width={itemWidth}
              height={itemWidth}
              alt={`${galleryName ?? ''} gallery picture ${index}`}
              className="max-h-full text-xs"
              onClick={openImage(index)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {popup && (
        <GalleryModal
          startFrom={imageIndex}
          content={content}
          galleryName={galleryName}
          onClose={() => setImageImage(null)}
        />
      )}
    </>
  )
}

export default Gallery
