import { KeyboardEvent } from 'react'

export const skipDecimalNumber = (
  event: KeyboardEvent<HTMLInputElement | HTMLDivElement>
) => {
  if (event.key === '.') {
    event.preventDefault()
  }
}

export const skipNumbers = (
  event: KeyboardEvent<HTMLInputElement | HTMLDivElement>,
  skip: boolean
) => {
  const numbers = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'])

  const shouldPreventDefault = skip
    ? numbers.has(event.key)
    : !numbers.has(event.key) && event.key !== 'Backspace'

  if (shouldPreventDefault) {
    event.preventDefault()
  }
}

export const skipCharacters = (
  event: KeyboardEvent<HTMLInputElement | HTMLDivElement>
) => {
  const forbiddenCharacters = ['+', ',', '-', '/', '*']
  if (forbiddenCharacters.includes(event.key)) {
    event.preventDefault()
  }
}
export const pad = (num: number, size: number) => {
  let numStr = `${num}`
  while (numStr.length < size) {
    numStr = '0' + numStr
  }
  return numStr
}
