import { useQuery } from '@tanstack/react-query'
import { IMatchAdvertFull } from 'data-transfers/dto'
import { IMatchingRequest } from 'data-transfers/requests'
import { IPaginatedResponse } from 'data-transfers/responses'

import { IUseQueryHookWithPayload } from 'hooks/types'

import { candidateMatchesKeys } from './keys'
import { getAdvertsMatchesByCandidateIdSwiper } from './matching.service'

const useCandidateMatchesSwiper = ({
  payload,
  options
}: IUseQueryHookWithPayload<
  IMatchingRequest,
  IPaginatedResponse<IMatchAdvertFull>
>) => {
  return useQuery<IPaginatedResponse<IMatchAdvertFull>>(
    candidateMatchesKeys.matching(payload),
    async () => {
      const response = await getAdvertsMatchesByCandidateIdSwiper(payload)
      return response
    },
    {
      keepPreviousData: true,
      ...options
    }
  )
}

export default useCandidateMatchesSwiper
