import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'

import { SectionEditButton } from 'components/buttons'

import { OrgLanguages } from './index'
import { ISectionType } from './types'

const OrgLanguagesSection = ({
  org,
  editable,
  onEditableClick
}: ISectionType) => {
  const openLanguagesModal = useCallback(() => {
    onEditableClick?.({ modal: 'languages' })
  }, [onEditableClick])
  const { t } = useTranslation('organisation')
  return (
    <section className="relative py-5">
      <div className="">
        <div className="flex items-start justify-between">
          <h2 className="mb-8 text-2xl font-bold tracking-wide">
            {t('languagesTitle', { ns: 'organisation' })}
            <span className="dot"></span>
          </h2>
          {editable && (
            <SectionEditButton
              data-cy="edit-lang-modal"
              className="absolute top-0 right-0"
              onClick={openLanguagesModal}
            />
          )}
        </div>
        <OrgLanguages languages={org.languages} />
      </div>
    </section>
  )
}

export default OrgLanguagesSection
