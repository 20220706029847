import { useCallback, useEffect, useRef } from 'react'

import { getYoutubeCode } from 'utils'

import { IVideoItemProps } from '../types'

const VideoItem = ({ src, isActive }: IVideoItemProps) => {
  const video = useRef<HTMLIFrameElement>(null)
  const pauseVideo = useCallback(() => {
    video.current?.contentWindow?.postMessage(
      '{"event":"command","func":"pauseVideo","args":""}',
      '*'
    )
  }, [])

  useEffect(() => {
    if (!isActive) {
      pauseVideo()
    }
  }, [isActive, pauseVideo])

  return (
    <iframe
      ref={video}
      width="100%"
      height="100%"
      className="aspect-video"
      src={`https://www.youtube.com/embed/${getYoutubeCode(
        src
      )}/?enablejsapi=1`}
      title="Company video"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      sandbox="allow-scripts allow-same-origin allow-presentation"
    />
  )
}
export default VideoItem
